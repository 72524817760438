import { EnvConfig } from "./envconfig";

export const environment: EnvConfig = {
  production: true,
  sbdBaseUrl: 'https://staging-accounting.suretybondsdirect.com',
  apiBaseUrl: 'https://staging-accounting.suretybondsdirect.com/admin',
  dougV2BaseUrl: 'https://staging-accounting.suretybondsdirect.com',
  titleBase: '(STGAcct) Doug',
  shouldCathyBeChatty: true
};
